import * as React from 'react';

import Layout from '../../components/layout';
import CSCSieves from '../../images/csc-sieves';

export default () => (
  <Layout>
    <div className="bg-gray-100">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div className="relative h-full mx-auto text-lg max-w-prose">
                <svg
                  className="absolute transform translate-x-32 top-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                  />
                </svg>
                <svg
                  className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute transform translate-x-32 bottom-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                  />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto mb-6 text-lg prose">
                <p className="text-base font-semibold leading-6 tracking-wide text-center text-teal-600 uppercase">
                  Case study
                </p>
                <h1 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
                  How CSC Scientific Saved $25K/year by Switching from Netsuite
                  to Odoo
                </h1>
                <p>
                  <a href="https://www.cscscientific.com/">CSC Scientific</a>{' '}
                  produce and sell industrial instruments and equipment. Until
                  2016 they used Netsuite ERP to run their business. Then they
                  discovered Odoo, and were immediately attracted by its modern
                  interface and flexible workflow, all for a fifth of the cost
                  of Netsuite.
                </p>
                <p>
                  As it came time for CSC Scientific to renew their Netsuite
                  subscription, they knew that it would be difficult to
                  negotiate a price anywhere near the cost of Odoo.
                </p>
                <p>
                  Instead, with the help of a consultant they recreated their
                  current workflow in Odoo, implementing the same apps they used
                  with Netsuite, plus a few new ones from Odoo. Currently they
                  use Sales, Accounting, Invoicing, Inventory, Purchasing,
                  Manufacturing, Inventory, Website and eCommerce, with two
                  extra integrations; FedEx and UPS.
                </p>
                <figure>
                  <CSCSieves className="w-full rounded" />
                  <figcaption className="text-center">
                    Precision sieves from CSC Manufacturing
                  </figcaption>
                </figure>
                <p>
                  It took CSC Scientific less than four months, with 100 hours
                  of consulting, to implement Odoo and train its users. In
                  addition to giving them a more modern and functional ERP
                  system, the switch reduced their annual costs by $25,000.
                </p>
                <p>
                  (Original article{' '}
                  <a href="https://www.odoo.com/blog/customer-reviews-6/post/how-csc-scientific-saved-25000-per-year-by-switching-from-netsuite-to-odoo-354">
                    here
                  </a>
                  .)
                </p>
                <hr />
                <p>
                  Want to hear more? Contact us for a demo. Email{' '}
                  <a href="mailto:chris@monterey.dev">chris@monterey.dev</a> for
                  details.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
